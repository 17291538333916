/* MOBILE */
@import "~react-image-gallery/styles/css/image-gallery.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
div,
input,
select,
textarea,
td,
button,
tr,
thead,
article,
header,
footer,
strong {
  font-family: "Poppins", sans-serif !important;
}
body,
#root {
  width: 100vw;
  overflow-x: hidden;
}
body a,
body a:hover {
  color: black;
  text-decoration: none;
}

.success {
  color: green;
  font-size: 17px;
}

.error {
  color: red;
  font-size: 17px;
}

.mobileMenu svg {
  font-size: 30px;
  color: white;
}

.blackText {
  color: black !important;
}
.whiteText {
  color: white !important;
}

.comeback a {
  color: black;
  text-decoration: none;
}

.comeback a:hover {
  color: black;
  text-decoration: none;
}

.weightText {
  font-weight: 700;
}

.banerParams {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banerParams input {
  max-width: 90vw;
  width: 500px;
  margin: auto;
  padding: 5px 10px;
  height: 50px;
}

#header {
  height: fit-content;
  background: url("assets/waveh.svg") no-repeat bottom #2d2e33;
  background-size: contain;
}
.detailHeader #header {
  background: url("assets/falaSmaller.svg") no-repeat bottom #2d2e33;
  background-size: contain;
}
.selectAddBaner {
  padding: 10px 20px;
  width: 300px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid #f7aa26;
  max-width: 90%;
}

#header #logo img {
  max-height: 130px !important;
}
img.logo {
  max-height: 130px !important;
}
#mobileMenuImg {
  padding-left: 1.5rem;
}

#header .btn.add_offer,
#header .btn.add_offer:hover {
  background: #f7aa26;
  /* box-shadow: 3px 3px 10px #cb5e47; */
  border-radius: 6px;
  padding: 18px 24px;
  color: white;
  font-weight: 700;
  font-size: 16px;
}

#header .btn.your_account,
#header .btn.your_account:hover {
  background: #f7aa26;
  border-radius: 6px;
  padding: 18px 24px;
  color: white;
  font-weight: 700;
  font-size: 16px;
}
#header .btn.your_account svg {
  margin-right: 10px;
  font-size: 20px;
}

#header .buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

#header .menu {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  color: white;
}

#header .menu div {
  cursor: pointer;
}

#header .menu div svg {
  margin-right: 10px;
  font-size: 20px;
}
.Hero {
  color: white;
  z-index: 999;
}
.heroTitle {
  font-weight: 700;
  font-size: 64px;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.titleSearch .heroTitle {
  font-size: 40px;
  line-height: 60px;
}

.titleSearch .yellow_circle {
  width: 60px;
  height: 60px;
}

.yellow_circle {
  width: 80px;
  height: 80px;
  background: #f7aa26;
  border-radius: 50%;
}
.closeBackground svg {
  padding: 10px;
  background: white;
  color: black;
}

#searchWrapper {
  z-index: 999;
  position: relative;
  background: #ffffff;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  align-items: center;
}

#searchWrapper .input-group-text {
  background: transparent;
  border: none;
}
#searchWrapper .form-control {
  border: none;
  color: black;
}
#searchWrapper .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}
#searchWrapper svg {
  color: #c4c4c4;
  font-size: 22px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

#searchWrapper ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

#searchWrapper :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

#searchWrapper ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

#searchWrapper .searchButton {
  background: #2d2e33;
  border-radius: 6px;
  color: white;
  padding: 18px 20px;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
}

.searchButton:hover {
  background: black !important;
  color: white !important;
}
.blankImg {
  background-color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 10px;
  font-size: 30px;
  cursor: pointer;
}
.buttonWrapperLogin .searchButton {
  background: #2d2e33;
  border-radius: 6px;
  color: white;
  padding: 18px 20px;
  font-weight: 700;
  font-size: 16px;
}

#searchContainer {
  padding-bottom: 6rem !important;
  z-index: 0;
  position: relative;
}

.Hero {
  position: relative;
}

.heroMan img {
  position: absolute;
  bottom: -100px;
  right: 0;
}

#mainPage {
  margin-top: 3rem;
  z-index: 99;
  position: relative;
}

#mainPage .title {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 64px;
  color: black;
}

.price_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.price_list .price {
  font-weight: 700;
  font-size: 25px;
  color: #000000;
}

.price_list svg {
  font-size: 24px;
}
.wishlist svg {
  cursor: pointer;
  color: #ec930f;
}
.gridProductWrapper {
  cursor: pointer;
  background-color: white;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 20px 2px rgba(76, 15, 5, 0.1);
  border-radius: 4px;
  height: 320px;
}
.detailOfferHero {
  font-size: 40px;
}
.gridProductWrapper img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.price#free {
  color: green;
}
#listProduct {
  cursor: pointer;
  background-color: white;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 20px 2px rgba(76, 15, 5, 0.1);
  border-radius: 0px;
}
#listProduct .image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.gridProductWrapper .productTitle {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.condition {
  text-transform: lowercase;
}

#listProduct .productTitle {
  font-size: 1.7rem;
  font-weight: 700;
}

#listProduct .description {
  display: flex;
  flex-direction: column;
}

#listProduct .description button {
  width: fit-content;
  padding: 5px 50px;
  height: fit-content;
  border: none;
  font-weight: 600;
  font-size: 18px;
}

#listProduct .description button.new {
  color: white;
  background: black;
  box-shadow: 0px 4px 20px 2px rgba(76, 15, 5, 0.1);
  border-radius: 6px;
  cursor: pointer;
}

.listEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
}

.listEnd .priceCity {
  color: #46535a;
  text-align: end;
}
#centerWrapper .thankYouOffer h2 {
  font-size: 90px;
  max-width: 800px;
}
.thankYouOffer .buttons_row {
  display: flex;
  gap: 40px;
}
.thankYouOffer .buttons_row button {
  min-width: 50%;
  width: 400px;
  font-weight: 800;
  font-size: 18px;
}

.thankYouOffer .buttons_row .promo {
  background: #f7aa26;
}

.listEnd .wishlist {
  font-size: 30px;
}
.listEnd .price {
  font-weight: 700;
  font-size: 24px;
  color: black;
}
#listProduct .row {
  position: relative;
}

.heromobiletitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 40px;
  font-weight: 700;
}

.highlighted {
  position: absolute;
  bottom: -1rem;
  left: 0;
  background: #ec930f;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px 40px;
  font-weight: 700;
  color: black;
}
#listProduct.listProductPanel .date {
  font-size: 1rem;
  color: black;
  font-weight: 300;
}

.listProductPanel .categoriesPath {
  font-weight: 500;
  font-size: 20px;
}

#listProduct .date {
  font-size: 1.4rem;
  color: black;
}
#listProduct .description button.old {
  color: black;
  background: #f7aa26;
  box-shadow: 0px 4px 20px 2px rgba(76, 15, 5, 0.1);
  border-radius: 6px;
  cursor: pointer;
}

.gridProductWrapper .description {
  text-align: center;
}
.categories.wider {
  min-width: 200px;
  text-align: center;
}
.searchInput:focus {
  outline: none;
}
.clearCategoryOFfers {
  font-size: 30px;
  display: flex;
  align-items: center;
  color: red;
  cursor: pointer;
}
.categories.wider .form-control {
  justify-content: center;
}
.rangeCategories a {
  text-decoration: none;
  color: black;
}
.rangeCategories.dates {
  overflow: hidden;
  padding: 20px 0;
}
.rangeCategories.dates input {
  width: fit-content;
  border: none;
}

.rangeCategories.dates input:focus {
  outline: none;
}
.gridProductWrapper span {
  color: #46535a;
  font-weight: 300;
  font-size: 18px;
}
.offersHome {
  position: relative;
}
.shape0 {
  position: absolute;
  left: 0;
  top: 10%;
  max-height: 100%;
}

.shape1 {
  position: absolute;
  right: 0;
  max-height: 100%;
  bottom: 10%;
}

.backgroundGray {
  background-color: #e4e4e4;
}

.footerAdvertisement .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.newMess svg {
  color: #f7aa26;
}
.footerAdvertisement {
  background: linear-gradient(0deg, #f36a3e 35%, #ffb30b 100%);
  cursor: pointer;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}
.noOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.social div {
  background: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.social svg {
  font-size: 20px;
}

.ideaPapier {
  background: url("assets/wave.png") no-repeat bottom #2d2e33;
  background-size: contain;
  position: relative;
}

.figury1 {
  position: absolute;
  top: 10%;
  left: 10%;
}

.figury2 {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.fontWeight {
  font-weight: 700 !important;
}
.ideaPapier .ideaHeader {
  font-weight: 700;
  font-size: 30px;
  color: white;
}
.ideaPapier .description {
  color: #ffffff;
  font-weight: 700;
  font-size: 25px;
  width: 800px;
  max-width: 90%;
  margin: auto;
}
.categoryHomePage {
  max-height: 140px !important;
}
.containerIdea {
  position: relative;
  z-index: 999;
}

.footer {
  background-color: #2d2e33;
}

.footer .social {
  justify-content: flex-start;
}

.footer .logo {
  max-height: 140px;
}

.footer h5 {
  color: white;
}

.footer {
  color: white;
}

.footer .line {
  opacity: 0.8;
  color: #eeeeee;
}

.footer .copyright {
  text-align: center;
  color: #f7aa26;
}
#catalogLink {
  text-align: center;
}
#catalogLink img {
  height: 150px;
  object-fit: cover;
  width: 100%;
}
.ReactModal__Overlay {
  z-index: 999;
}

/* LOGIN REGISTER */

.register {
  min-height: 100vh;
  position: relative;
}
.registerCloseWrap {
  position: absolute;
  color: white;
  top: 40px;
  right: 40px;
  border: 2px solid #eeeeee;
}
.registerCloseWrap svg {
  font-size: 30px;
  color: black;
}
.registerCloseWrap.whiteText svg {
  color: white;
}
.register.left-side {
  background: #f3f3f3;
  background-size: cover;
  background-position: center;
}
.inputIconRegister {
  height: 50px;
  margin: auto;
  background: white;
  background-color: white !important;
  border-left: none !important;
}
.registerImgWrapper {
  max-width: 70%;
  margin: auto;
  margin-top: 10%;
}
.loginWrapper svg {
  font-size: 30px;
  /* color: white; */
}
.loginWrapper .login {
  color: white;
}
.loginWrapper {
  position: relative;
}
.loginWrapper h3 {
  color: white;
}
.circle_yellow_grad {
  position: absolute;
  width: 40vh;
  height: 40vh;
  background: linear-gradient(
    98.08deg,
    #3b3f46 -25.95%,
    #f5b301 5.82%,
    #ffd608 35.48%
  );
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.25);
  z-index: 999;
  border-radius: 50%;
  top: -13vh;
  left: -20%;
  z-index: -1;
}

.DeskopMenu .menu a {
  color: white;
  text-decoration: none;
}

.loginWrapper p {
  font-size: 22px;
  margin-bottom: 0;
}
.buttonWrapperLogin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonWrapperLogin .loginmaybe a {
  text-decoration: dotted;
  font-size: 20px;
}

.buttonWrapperLogin .loginmaybe span {
  color: #f7aa26;
}

.onCenter {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.onCenter form {
  width: 30rem;
  max-width: 100%;
}

.mylabel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.mylabel input {
  transform: scale(1.2);
}
.onCenter form input {
  margin: 10px 0;
}
.register .onCenter form input {
  margin: 5px 0;
}
.inputRegister {
  height: 50px;
  border-radius: 0;
  text-align: left;
  font-size: 18px;
  color: black;
  border-right: none !important;
}
.inputRegisterRight {
  height: 50px;
  border-radius: 0;
  text-align: left;
  font-size: 18px;
  color: black;
}
.inputRegister.solo {
  border-right: 1px solid #ced4da;
}

.inputRegister:focus {
  outline: none !important;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.loginmaybe a {
  color: black;
  font-size: 16px;
}
.loginmaybe {
  display: flex;
  align-items: center;
}

.register.left-side {
  background-color: #2d2e33;
  position: relative;
}

.loginshape0 {
  position: absolute;
  left: 0;
  width: 60%;
}
.loginMain {
  position: absolute;
  top: -10%;
  left: -20%;
  z-index: -1;
}
.loginshape1 {
  top: 0;
  position: absolute;
  right: 0;
}

.registerImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}
.registerImgWrapper#registerOnly {
  align-items: unset;
  margin-top: 200px;
  height: fit-content;
  margin-bottom: 200px;
}
#thankyouRegister {
  background: url("assets/backgroundThankYou.png") no-repeat bottom right,
    #2d2e33;
  background-size: contain;
  min-height: 100vh;
}

#centerWrapper {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 10vh 0;
  display: flex;
}

#banerCenter {
  min-height: 100vh;
  padding: 10vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#banerCenter .row {
  background-color: white;
  z-index: 999;
  position: relative;
  border-radius: 20px;
  height: fit-content;
  width: 100%;
}
#banerCenter .row h2 {
  font-size: 80px;
  color: black;
  text-align: center;
}

.banerWrapper {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homeButton {
  background: #000000;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  border: 1px solid black;
}

.newButton {
  background: #f7aa26;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  border: 1px solid #f7aa26;
}
.banerWrapper button {
  width: fit-content;
  height: 50px;
  padding: 0px 20px;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
}

#centerWrapper .row {
  background-color: white;
  border-radius: 20px;
  z-index: 999;
  position: relative;
}
#centerWrapper .myWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.myWrapper p {
  max-width: 400px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}

.myWrapper button {
  background: #000000;
  border-radius: 6px;
  padding: 20px 30px;
  color: white;
  border: none;
}

#centerWrapper h2 {
  color: black;
  font-size: 90px;
  max-width: 600px;
  text-align: center;
}

.ThankYouWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#thankYou p {
  color: black;
  transform: rotate(-90deg);
  word-break: keep-all;
}

.registerImgWrapper .yellow_circle {
  position: absolute;
  width: 40vh;
  height: 40vh;
  left: -5vh;
}

.registerImgWrapper .registerTitle {
  position: relative;
  font-size: 80px;
  transform: rotate(-25deg);
  margin-bottom: 20vh;
  color: #000000;
  z-index: 99;
}
.registerImgWrapper h3 span {
  color: white;
}

.yellow_plama {
  position: absolute;
  top: 0;
  right: 0;
  height: 30vh;
}

.href-login:hover {
  color: black;
}

.href-login {
  font-size: 22px;
}

.href-login span {
  background: -webkit-linear-gradient(#fde723, #f8c71c, #f59811);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 3px dashed #fde723;
}

.loginLabel {
  margin-top: 10px;
}
.loginLabel input {
  margin-right: 10px !important;
}

.social svg {
  color: #2c373e;
}
.logoFooterText {
  max-width: 500px;
}
.selectedCategory {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  cursor: pointer;
}
.btn.catalog,
.btn.catalog:hover,
.btn.catalog:active {
  background: #7c7c7c;
  border-radius: 6px;
  padding: 18px 24px;
  color: white;
  font-weight: 700;
  font-size: 16px;
}
.selectedCategory .customButton {
  padding: 20px 10px;
  text-align: left;
  background: #f7aa26;
  width: 60%;
  border-radius: 6px;
}
.selectedCategory .customButton h3 {
  font-size: 22px;
  text-align: left;
}
.selectedCategory .customButton p {
  color: white;
  font-weight: 400;
}
.change {
  border-bottom: 2px solid #f7aa26;
  padding: 0 15px;
  font-size: 22px;
  color: #f7aa26;
  font-weight: 600;
}
.menuOverflow {
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #2d2e33;
}

#menuWrapperRow {
  align-items: center;
}

#mobileMenuClose {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: white;
}

.menuItems {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 5vh !important;
  justify-content: space-between;
}

.menuWrapper {
  height: 80vh;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuItems .item {
  font-size: 24px;
  color: white;
  line-height: 80px;
  border-bottom: 1px solid #f7aa26;
}
.buttonItem {
  padding: 0 20px;
}

.menuItems .item a {
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.buttonItem button {
  width: 100%;
  height: 50px;
  margin-top: 5vh;
  background: #f7aa26;
  color: white;
  border-radius: 6px;
  border: none;
}

.LOGIN_REGISTER_MOBILE {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
  position: relative;
  width: 90%;
}

.LOGIN_REGISTER_MOBILE form {
  width: fit-content;
}
.LOGIN_REGISTER_MOBILE .buttonWrapperLogin {
  flex-direction: column;
}

.LOGIN_REGISTER_MOBILE button {
  background: #f7aa26;
  color: white;
  height: 45px;
  border-radius: 6px;
  border: none;
}

.LOGIN_REGISTER_MOBILE .loginmaybe {
  justify-content: center;
  margin-top: 20px;
}

.LOGIN_REGISTER_MOBILE .logo {
  position: absolute;
  top: -180px;
}

.largerCircle {
  position: absolute;
  top: 2.5vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 95vh;
  height: 95vh;
  background-color: #ecc80f;
  z-index: 1;
  border-radius: 50%;
}
.largeCircle {
  position: absolute;
  top: 7.5vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 85vh;
  height: 85vh;
  background-color: #000000;
  z-index: 1;
  border-radius: 50%;
}

.imgWrapper {
  text-align: center;
}
#thankyouRegister #centerWrapper {
  min-height: 75vh;
}
.cornerEclipse {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px;
}

.cornerEclipse2 {
  position: absolute;
  bottom: 0;
  transform: rotate(90deg);
}
.only_mobile .categories {
  width: 100%;
}
.searchDropDown {
  position: relative;
  width: 100%;
}

#header .searchDropDown {
  width: fit-content;
}

.myCustomRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 10px;
}

.myCustomRow .image {
  width: 50px;
}

.myCustomRow.image img {
  border-radius: 50%;
}

.categories.extend {
  width: fit-content;
}
.searchDropDown:hover .rangeCategories {
  border-radius: 10px;
  display: block;
  /* margin: 0 10px; */
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  text-align: center;
  width: 100%;
  color: black;
}
.heroTitleSearch {
  font-size: 50px;
}
.categories:hover .rangeCategories {
  border-radius: 10px;
  display: block;
  /* margin: 0 10px; */
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  text-align: center;
  width: 100%;
}
.priceFilter:hover .rangeCategories {
  border-radius: 10px;
  display: block;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  text-align: center;
  width: 100%;
}
.oneRow {
  display: flex;
  gap: 30px;
}
.itemRange {
  padding: 8px 10px;
  font-size: 18px;
  cursor: pointer;
}

.itemRange:hover {
  background-color: #2d2e33;
  color: white;
}

#SearchOffers .itemRange:hover {
  background-color: #f7aa26;
}

.rangeCategories {
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 999;
  width: 100%;
  font-size: 18px;
}
.priceButton {
  width: 100%;
}
.priceFilter {
  max-width: 100px;
  position: relative;
}
.categories {
  position: relative;
}
.categories button {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.categories,
.priceFilter {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  height: 50px;
}
.categories button,
.priceFilter input {
  height: 100%;
}
.categories {
  width: 40%;
}

.gridList {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 30px;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.gridList i {
  cursor: pointer;
}

.rotateLine {
  height: 2px !important;
  opacity: 1 !important;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 30px;
  border-top: 3px solid #ecc80f !important;
}
.filtersWrapper .categories {
  width: fit-content;
}
.filtrButton {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 22px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  height: 50px;
}
.ThankYouWrapper .successHeroTitle {
  font-size: 50px;
}

.filtrButton svg {
  font-size: 20px;
}

.only_mobile .wishlist {
  font-size: 20px;
}

.bottomLine {
  width: fit-content;
  padding: 10px 0;
  border-bottom: 3px solid white;
}

.filtersMobile .flex-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.filtersMobile .flex-rows button {
  background: #0e0d0d;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 5px 10px;
  color: white;
}

.filtersMobile .flex-rows button#active {
  background: #ecc80f;
  color: black;
}

.searchButtonFilters {
  background: #000000;
  color: white;
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
}
@supports (bottom: env(safe-area-inset-bottom)) {
  .mobileBottomContent {
    position: fixed;
    left: 0;
    width: clac(100vw - 20px);
    background: url("assets/mobileFilters.png") no-repeat bottom right, #2d2e33;
    background-size: auto;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    bottom: calc(10px + env(safe-area-inset-bottom)) !important;
  }
}
.filtersMobile {
  height: 100vh;
  background: url("assets/mobileFilters.png") no-repeat bottom right, #2d2e33;
  background-size: auto;
  width: 100vw;
}
.mobileBottomContent {
  position: fixed;
  left: 10px;
  width: calc(100vw - 20px);
  z-index: 9999;
}

.filtersMobile img {
  height: 40px;
}
html .filtersMobileRow {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.filtersMobile .categoryClickable {
  padding: 5px 20px !important;
}
.mobilePhraseSearch {
  padding: 5px 20px;
  max-width: 80%;
  border: none;
  color: black;
}
.mobilePhraseSearchWrapper span {
  background: white;
  border: none;
}
.filtersMobile .categoryClickable.submit {
  justify-content: center;
  background: #f7aa26;
}
.filtersMobile .line_under {
  margin-top: 5px;
}
.filtersMobileRow .negativeMargin {
  margin-left: -20px;
}
.filtersMobileRow .zIndexHiger {
  z-index: 9;
}
.filtersMobileRow .oneRowFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filtersMobile .categoryClickable {
  border: none;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  color: black !important;
}
.categoryClickable i {
  font-size: 1.5rem;
  color: black;
}

.filtersMobile .priceFilters {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  color: black;
  gap: 10px;
  align-items: center;
}

.filtersMobile .priceInput {
  height: 35px;
  text-align: center;
}

.filtersMobile .filtersClose .clear {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
}

.filtersMobile .filtersClose {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 20px;
  color: white;
  z-index: 999999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}
.filtersMobile .filtersClose svg {
  font-size: 40px;
  z-index: 999999;
}
.filtersMobile .top {
  font-size: 22px;
  color: white;
  font-weight: 800;
}
.filtersMobile .wrapper {
  padding: 20px;
  background: #ffffff;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.15);
  border-radius: 13px;
}
.filtersMobile .categoriesWrapper {
  height: -webkit-fill-available;
  margin-top: 70px;
  overflow: hidden;
}

.filtersMobile .categoriesScrollable {
  max-height: 85%;
  overflow-y: auto;
}

.noborder {
  border: none !important;
}

.searchFilterMobile {
  border-radius: 6px;
}

.searchFilterMobile input {
  background: #e7e7e7 !important;
}

.searchFilterMobile .inputIconRegister {
  background: #e7e7e7 !important;
}
.cityClicable {
  width: 100%;
  border: none;
  height: 40px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobileRadius {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.mobileRadius input {
  width: 60%;
  padding: 5px 30px;
}
.mobileRadius .switch {
  max-width: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 1px solid black;
  border-radius: 10px;
  height: 50px;
}

.detailSearch #searchContainer {
  margin-top: 0 !important;
}

#offerDetail {
  background: #ffffff;
}

.back_bread {
  display: flex;
  flex-direction: row;
  gap: 50px;
  font-size: 18px;
}
.back_bread .breadcamp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back_bread .comeback {
  font-size: 30px;
  font-weight: 700;
}

.singleProductImg {
  text-align: center;
}
#checkoutPromo .circleImg {
  height: 120px;
  width: 120px;
}
.paymentMethod {
  max-width: 400px;
  text-align: center;
  border: 1px solid #f7aa26;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  padding: 20px;
  cursor: pointer;
}

hr.underline {
  width: 50%;
  margin: auto;
  border-bottom: 2px solid #f7aa26;
  color: #f7aa26;
  opacity: 1;
}
img.circleImg {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}
#offerDetail .section {
  padding: 20px;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  background-color: white;
}
#offerDetail .sellerName {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0 !important;
}
.sendMessageButton {
  width: 100%;
  max-width: 300px;
  background: #f7aa26;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
  color: black;
}
.SellerWrap .sendMessageButton {
  background: #f7aa26;
  height: 50px;
}
.SellerWrap button {
  color: black;
}

#buttonMessWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phoneNumber {
  width: 50px;
  height: 50px;
  background: #f7aa26;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.UnderMessageButton {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  font-size: 22px;
  justify-content: flex-end;
  font-weight: 700;
}

.buttonShow {
  background: #dcdcdc;
  border: none;
  border-radius: 6px;
  padding: 10px 24px;
}

.productData .wishlist {
  text-align: right;
  font-size: 26px;
}

.productData {
  color: black;
  display: flex;
  flex-direction: row;
  position: relative;
}
.productInfo {
  width: 90%;
}

.productData .promo {
  font-size: 20px;
}

.productData .tagInfo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-weight: 700;
  font-size: 16px;
}

.productData .tagInfo button {
  border: 1px solid black;
  padding: 10px 30px;
  border-radius: 8px;
  background-color: white;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.productData .description {
  max-width: 500px;
  word-break: break-all;
}

.productData .analytics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wishlist {
  width: 20%;
}

.buttonShowMobile {
  background: #dcdcdc;
  height: 50px;
  border: none;
  border-radius: 6px;
  width: 100%;
  max-width: 300px;
}

.Localization {
  color: black;
}
.Localization h4 {
  font-size: 30px;
  margin-bottom: 0;
  font-weight: 700;
}
.Localization p {
  font-size: 18px;
}

.redFlag {
  color: red;
  cursor: pointer;
}
.mapDetailOffer {
  min-height: 100px;
}

.ReactModal__Overlay {
  background-color: rgb(0 0 0 / 75%) !important;
}
.swiper {
  z-index: 0 !important;
}
.flexAlongsidemap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textareaCompany {
  background: #dcdcdc;
  border-radius: 6px;
  position: relative;
}

.textareaCompany span {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

#checkoutPromo .buttonsPrice button.priceButton {
  width: 200px;
  max-width: 45%;
  gap: 30px;
}

.infoForm {
  background: rgba(247, 170, 38, 0.4);
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}

.buttonsPrice {
  display: flex;
  gap: 20px;
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  width: fit-content;
}

.buttonsPrice button.priceButton {
  width: 150px;
  max-width: 45%;
}
#checkoutPromo input[type="checkbox"] {
  transform: scale(1.5);
  margin-top: 10px;
}

#checkoutPromo input[type="text"] {
  margin: 10px 0;
  height: 50px;
}

#checkoutPromo .section {
  max-width: 500px;
}
.checkoutLabel {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 2vw;
  font-size: 18px;
}
.checkoutButtonsDis button:disabled {
  opacity: 0.5;
}
#checkoutPromo .add_offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background: #f7aa26;
  padding: 10px 20px;
  height: 50px;
}
.categoryModalHandler {
  cursor: pointer;
}
.buttonsPrice button {
  border: none;
  padding: 10px 20px;
}
.buttonsPrice button#active {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.section {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  padding: 30px 40px;
}

.promosection {
  background: #ffffff;
  color: black;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  padding: 20px;
}

.promosection#active {
  background: #ffffff;
  border: 3px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.2);
}

#addOffer .removeGalleryItem {
  font-size: 40px;
  color: red;
  position: absolute;
  right: 0;
}

.categories-modal-container {
  min-width: 30vw;
  max-width: 1200px;
  width: 90vw;
  min-height: 20vh;
  max-height: 900px;
  height: 70vh;
}
.searchDropDown .form-control:disabled {
  background-color: white;
}
.categoryInput {
  cursor: pointer;
}
#addOffer .image {
  cursor: pointer;
  text-align: center;
  height: 150px;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
}
.backModal {
  cursor: pointer;
}
#addOffer .images img {
  height: 100%;
  width: 100%;
  display: flex;
  object-fit: contain;
}
#addOffer .dragged img {
  height: 100%;
  width: 100%;
  display: flex;
  object-fit: contain;
}
.dragged .removeGalleryItem {
  display: none;
}
.dragged {
  text-align: center;
}
.dragged img {
  max-height: 150px;
}
#addOffer .imageInner {
  position: relative;
  height: 150px;
  object-fit: contain;
}
#addOffer .images svg {
  font-size: 30px;
}
.swiper-slide {
  height: 500px !important;
  display: flex !important;
  justify-content: center !important;
}
.swiper-slide img {
  object-fit: cover;
}
.categories-modal-container {
  width: fit-content;
}

.comeback-modal-container {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: flex-end;
}
.categories-modal-container {
  height: 100%;
}
.categories-modal-container .head {
  height: 20%;
}

.categories-modal-container .body {
  height: 60%;
}
.categoriesList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.categories-modal-container .comeback-modal-container {
  height: 20%;
}
.categoryModal {
  padding: 20px 40px;
  background: #dcdcdc;
  color: black;
  font-size: 700;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#addOffer .images #first {
  background: #f7aa26;
}
#addOffer .controls {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  padding: 20px 0;
}

#addOffer .controls svg {
  cursor: pointer;
}

#addOffer input,
#addOffer textarea,
#addOffer select {
  background: #dcdcdc;
  border-radius: 6px;
  min-height: 50px;
}
.no_border_right {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#addOffer .input-group-text {
  background: #dcdcdc;
  border-left: none;
}

#addOffer input,
#addOffer .makeShorter {
  min-height: 50px;
  min-width: 60%;
  max-width: 400px;
}

#addOffer .searchDropDown {
  max-width: 400px;
}

#addOffer .searchDropDown .inputWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.sectionButtons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-end;
}

.dragged {
  background-color: white;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
}

.sectionButtons button {
  border: none;
  border-radius: 6px;
  color: black;
  height: 50px;
  font-weight: 700;
  font-size: 18px;
}

.sectionButtons .buttonAddOffer {
  background: #f7aa26;
  padding: 10px 50px;
}

.sectionButtons .buttonPodglad {
  background: #e0e0e0;
  padding: 10px 20px;
}

#addOffer .searchDropDown input {
  width: 100%;
}
#addOffer {
  color: black;
}

#addOffer p {
  font-size: 20px;
}

#addOffer h1 {
  font-weight: 800;
  font-size: 40px;
  color: black;
}

.underTextArea {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.underTextArea p {
  margin-bottom: 0;
}

.promoTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.promoTop p {
  margin-bottom: 0;
  font-size: 18px;
}

.modalTextarea {
  width: 100%;
}

.promosection ul {
  list-style-type: "✓";
  font-size: 18px;
}

.promosection button {
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.promosection button#active {
  background-color: #e0e0e0;
}

.promosection {
  cursor: pointer;
}

.pricePromo {
  text-align: right;
  color: black;
  font-weight: 700;
}

.promoTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.promoTitle img {
  width: 50px;
}

.promoTop .one {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 30px;
  font-weight: 700;
}

.catalogItem {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  position: relative;
}
.catalogItem .topRow {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.catalogItem #desc {
  font-size: 16px;
  color: #46535a;
}

#catalogLink {
  cursor: pointer;
}

.arrowDetail {
  position: absolute;
  right: 2rem;
  bottom: 20px;
  cursor: pointer;
}
.arrowDetail img {
  width: 30px;
}

.CompanyInfoWrapper {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}
.pagination {
  text-align: center;
}

.paginationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.pageItem {
  cursor: pointer;
  border-radius: 50%;
  height: 40px;
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  color: black;
}

.pageItem#active {
  border: 1px solid black;
}
.CompanyInfoWrapper .inner {
  width: 800px;
  max-width: 100%;
}

.titleDetailCatalog {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 50px;
  color: black;
  font-weight: 700;
}

#sidebar {
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  color: white;
}

#sidebar .profileInfo .row {
  align-items: center;
}

#sidebar .sidebarItem {
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 20px;
  color: black;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

#sidebar .sidebarItem#active {
  background: #f7aa26;
  color: white;
}

#panelContent {
  position: relative;
  padding-right: 0 !important;
}
.panelBottomImg {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 200px !important;
  padding: 0 !important;
  z-index: -1;
}

.removeModal {
  text-align: center;
}
.removeModal h3 {
  line-height: 36px;
  font-size: 22px;
}

.removeOfferModalBody {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.removeOfferModalBody button {
  width: 50%;
  min-width: 200px;
}
.newKatalogs,
.hello {
  height: calc(50% - 0.5rem);
}

.removeOfferModalBody .buttonRemove {
  background: #d64000;
  border-radius: 6px;
  border: 1px solid #d64000;
  color: white;
}
.topSiderbarContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.topSiderbarContent img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
#sidebar .sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
#sidebar .sidebar.admin_sidebar {
  justify-content: flex-start;
  gap: 10vh;
}
#sidebar .adminSidebar .sidebarItem {
  color: white;
  background: transparent;
  justify-content: start;
  gap: 20px;
  font-size: 18px;
  align-items: center;
}
#sidebar .adminSidebar .sidebarItem i {
  font-size: 26px;
}

#sidebar .adminSidebar .sidebarItem#active {
  background: transparent;
  color: #f7aa26;
}
.adminSidebar {
  padding: 20px;
}
#sidebar .contentSidebar a {
  text-decoration: none;
}

.bottomSidebar .logoutButton,
.bottomSidebar .logoutButton:hover {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 10px 20px;
  color: black;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.panelTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}
.panelTop .second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.panelTop a {
  text-decoration: none;
  color: black;
}
.panelTop .help {
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  color: black;
}
.panelTop .help.colored {
  background: #f7aa26;
}

.panel {
  height: 100%;
  min-height: 100vh;
}

#panel {
  height: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#panel .accordion {
  width: 100%;
}

.registerCloseWrapLogo {
  position: absolute;
  top: 35px;
  left: 20px;
  width: 60%;
  max-width: 300px;
}

.free {
  color: green;
}

#panel .accordion-item {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  margin: 10px 0;
}
.acordionForm img {
  max-height: 60px !important;
}

#panel input {
  max-width: 400px;
  min-height: 50px;
  min-width: 50%;
  background: #dcdcdc;
  border-radius: 6px;
}
.CurstomRowProfile {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.accountData {
  word-break: break-all;
}
.contentAdmin {
  min-height: calc(90vh - 70px);
}
.accountData .nazwa {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.CurstomRowProfile img {
  border-radius: 50%;
}
.CurstomRowProfile img.circle {
  width: 60px;
  height: 60px;
}

.CurstomRowProfile p {
  margin-bottom: 0;
}

.buttonForm {
  background: #f7aa26;
  color: black;
  border: none;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  height: 50px;
  font-weight: 600;
}
.change {
  cursor: pointer;
}
.buttonForm.red {
  background: red;
  color: white;
}

.CurstomRowProfile .change {
  width: fit-content;
  cursor: pointer;
}
#panel .searchDropDown {
  max-width: 400px;
}
#panel .input-group-text {
  background: #dcdcdc;
}

#panel .accordion-button:focus {
  box-shadow: none;
}
.accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: white !important;
  border-bottom: none !important;
  border-color: #e7e7e7 !important;
}

.topFilters {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: flex-end;
  align-items: center;
}

.topFilters span {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
}

.topFilters span:hover {
  border-bottom: 1px solid black;
  color: black;
}

.topFilters span#active {
  border-bottom: 1px solid black;
  color: black;
}

.promo {
  cursor: pointer;
}

.topFilters button {
  background: rgba(247, 170, 38, 0.8);
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 10px 30px;
}
.bottomFilters .categories {
  height: 40px;
}
.bottomFilters input {
  padding: 0px 10px;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.bottomFilters button {
  background: #ffffff;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0px 20px;
}
.bottomFilters {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  height: 40px;
}

.rowWishlist {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowWishlist input {
  max-width: 400px;
  width: 50%;
  border: none;
}

.rowWishlist .input-group-text {
  background: none;
  border: none;
}

.bottomRowOfferPanel {
  align-items: center;
  border-top: 2px solid #eeeeee;
  padding-top: 10px;
}

#listOfferPanelBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#listOfferPanelBottom button {
  border-radius: 6px;
}

#deleteRowId {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.mobFilters button {
  width: 100%;
}
.mobFilters .categories {
  width: 100%;
}
.searchMobileOffers {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  height: 50px;
  border: none;
  padding: 10px;
}
.searchMobileOffers:focus {
  outline: none;
}
#deleteRowId .delete {
  margin-left: 10px;
  border: 1px solid #d64000;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 10px 20px;
  background-color: #d64000;
  font-weight: 700;
  font-size: 16px;
  color: white;
}

#listOfferPanelBottom .stop {
  border: 1px solid #0e0d0d;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 10px 20px;
  background-color: white;
  font-weight: 700;
  font-size: 16px;
  color: black;
}

#listOfferPanelBottom .edit {
  padding: 10px 20px;
  background-color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
  color: black;
}

#listOfferPanelBottom .promuj {
  background: #f7aa26;
  border-radius: 6px;
  padding: 10px 20px;
  border: 1px solid #f7aa26;
  font-weight: 700;
  font-size: 16px;
  color: black;
}

.panel .second {
  display: flex;
  flex-direction: row;
}

.panel .second .addOfferButtonPanel {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
}

.display_none {
  display: none;
}

.paymentsTable thead {
  background: #e0e0e0;
}

.paymentsTable td,
.paymentsTable th {
  text-align: center;
}
.emptyContent {
  text-align: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.emptyContent span {
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.addCatalogButton {
  height: 50px;
  width: fit-content;
  padding: 10px 20px;
  background: #f7aa26;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid #f7aa26;
  border-radius: 6px;
  color: black;
}
.emptyContent .addCatalogButton {
  height: 50px;
  width: fit-content;
  padding: 10px 20px;
  background: #f7aa26;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  color: black;
}
.imageWrapperForm {
  cursor: pointer;
}
.circleImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.catalog_content .inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.catalog_content .inputs input {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  height: 50px;
  padding: 10px 20px;
}
.catalog_content .inputs input:focus,
.catalog_content .inputs textarea:focus {
  outline: none;
}
.catalog_content .inputs textarea {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 10px 20px;
}
.catalog_content h5 {
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.buttonBottom {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.buttonBottom button {
  max-width: 100%;
  width: 300px;
  text-align: center;
  font-size: 18px;
  justify-content: center;
}

#helpModal .header {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}
#helpModal input {
  height: 40px;
  padding: 25px;
}

#helpModal input,
#helpModal textarea {
  width: 400px;
  max-width: 100%;
  margin: auto;
  background: #dcdcdc;
  border-radius: 6px;
}
#helpModal form {
  text-align: center;
}

#helpModal input[type="checkbox"] {
  width: 20px;
}

#helpModal label {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  width: 400px;
  max-width: 100%;
  margin: auto;
  text-align: justify;
}

#helpModal label span {
  font-size: 14px;
}
#helpModal label input {
  height: 20px;
  width: 20px;
  margin-top: 4px;
  margin-right: 20px;
  transform: scale(1.4);
}

#helpModal h4 {
  font-size: 18px;
}

#helpModal button {
  width: 400px;
  max-width: 100%;
}

.units {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
}
.mobileUserToggle {
  font-size: 30px;
}
.units label {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.units .unit {
  height: 25px;
  width: 25px;
  border: 1px solid #f7aa26;
  border-radius: 6px;
  background: white;
  margin-right: 10px;
}

.units .unit#active {
  background: #f7aa26;
}

.users {
  display: flex;
  flex-direction: column;
}
.users .user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.redOrNot {
  background: #f7aa26;
  border: 1px solid #f7aa26;
}
.users .user img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.user .myrow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.threads input {
  width: 80%;
  border: none;
}
.threads input:focus {
  outline: none;
}
.threads .input-group-text {
  background: white;
  border: none;
}
.threads .buttonsRow button {
  color: black;
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 6px;
  border: none;
  font-size: 18px;
}
.DeskopMenu .rangeCategories .image img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.DeskopMenu .rangeCategories {
  padding: 10px;
}

.users #active {
  border: 1px solid #ec930f;
}

.threads .users {
  max-height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
}

.threads .buttonsRow button#active {
  background: #f7aa26;
  color: white;
}
.emptyContent a button {
  margin: auto;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.topContainer .circleYellow {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 50%;
  background: #f7aa26;
  text-align: center;
  font-size: 20px;
}

.messageInput form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.messageInput form input {
  width: 75%;
  padding: 5px 20px;
  background: #e0e0e0;
  border-radius: 50px;
  border: none;
  height: 40px;
}

.messageInput form input:focus {
  outline: none;
}
.profileInfo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.messageContainer {
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.threadChatIMG {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.messageInput button {
  height: 40px;
  width: 15%;
  background: #f7aa26;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: none;
  cursor: pointer;
}

.helpFigureIMG {
  position: absolute;
  z-index: -1;
  right: 0;
  height: 30%;
  bottom: 30%;
}

.sidebar {
  position: fixed;
  left: 0;
  background: #2d2e33;
  min-height: 100vh;
  max-height: 100vh;
}

/* ADMIN */

.section.hello {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
}
.section.hello .adminRowTriple img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.portfolioIMAGE {
  height: 250px;
  position: relative;
  overflow: hidden;
}
.portfolioIMAGE img {
  object-fit: contain;
  height: 90%;
  text-align: center;
  max-width: 95%;
}

.adminRowTriple {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-end;
  text-align: center;
}

.hello .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 120px;
  background: rgba(247, 170, 38, 0.25);
  padding: 20px;
}
.hello .bottom {
  padding: 20px;
}
.adminImg {
  margin-top: -40px;
}

.hello .top img {
  position: absolute;
  top: 0;
  right: 0;
  height: 120px;
}

.font23 {
  font-weight: 500;
  font-size: 19.5px;
  color: #495057;
}

.font22 {
  font-weight: 400;
  font-size: 17px;
  color: #495057;
}
#red {
  color: red;
}

#green {
  color: green;
}
.up_down i {
  font-size: 30px;
}
.bell {
  position: relative;
  cursor: pointer;
}
.bell i {
  font-size: 35px;
  padding: 10px;
}
#admin {
  background: #f8f8fb;
}
#admin td {
  text-align: center;
}
.topSiderbarContent {
  background: white;
  gap: 20px;
}
.maxHeight {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.line {
  height: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.bell span {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  background: #f46a6a;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminTable .payed {
  background: #daf4eb;
  color: green;
  border-radius: 10px;
  padding: 5px;
}
#homeTopRight {
  height: 100%;
}

.btnAddCategorysmall {
  width: 100%;
  background: #fba81f;
  border-radius: 4px;
  color: white;
  border: 1px solid #fba81f;
}

.adminTable .buttonMoreInfo {
  background: #f7aa26;
  border-radius: 30px;
  border: 1px solid #f7aa26;
  padding: 5px 20px;
  color: white;
}
.adminTable .notpayed {
  background: #f46a6a4d;
  color: red;
  border-radius: 10px;
  padding: 5px;
}

.adresDateGrid {
  color: #46535a;
  font-weight: 300;
  font-size: 16px;
}

.procentage {
  background-color: #34c38f67;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: green;
}
#mainChartLeft .procentage {
  font-size: 14px;
}
.procentage#red {
  color: red;
  background-color: rgba(231, 70, 70, 0.342);
}

.catalogContactData {
  width: 400px;
}

.filterAdmin {
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.filterAdmin#active,
.filterAdmin:hover {
  border-bottom: 2px solid #f7aa26;
  color: #f7aa26;
}

.secondFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: max-content;
}
.secondFilters input:focus {
  outline: none;
}
.secondFilters .input-group-text {
  background-color: white;
  border-left: none;
  border-right: 1px solid #ced4da;
  border-radius: 30px;
}
.tableActions i {
  font-size: 30px;
  cursor: pointer;
}
#userDetail {
  color: black;
}
.categoriesCategory .itemRange {
  text-align: left;
}
.categoryForm .input-group-text {
  border-radius: 30px;
}
.categoryForm input[type="text"] {
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 30px;
}
.categoryForm .input-group-text {
  background: white;
}
.myButtonsAdmin button {
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 600;
  width: 300px;
  max-width: 100%;
}
.myButtonsAdmin .sendEmail {
  background: #f7aa26;
  border: 1px solid #f7aa26;
}

.myButtonsAdmin .edit {
  background: #247cff;
  border: 1px solid #247cff;
}

.myButtonsAdmin .save {
  background: #1c9e1a;
  border: 1px solid #1c9e1a;
  color: white;
}

.myButtonsAdmin .usun {
  background: #ff0000;
  border: 1px solid #ff0000;
  color: white;
}

.categoryForm input:focus {
  outline: none;
}

.multipleButtons .usun {
  background: #ff0000;
  border: 1px solid #ff0000;
  color: white;
  font-size: 19px;
  padding: 5px 20px;
  border-radius: 4px;
}

#userDetail input {
  background: #dcdcdc;
  border-radius: 6px;
  height: 50px;
  border: none;
  padding: 10px;
}

.profilePicture {
  width: fit-content;
  text-align: center;
}
#userDetail img {
  width: 80px;
  height: 80px;
}
.tableActions #red {
  color: red;
}
.no_border_left {
  border-left: none !important;
}
.multipleSelect {
  height: 15px;
  width: 15px;
  border: 2px solid #ced4da;
  border-radius: 2px;
  background: white;
}
.weightFont600 {
  font-weight: 600;
  color: black;
}
.adminTable {
  color: black;
}
.adminTable .active {
  background: #1c9e1a;
  border-radius: 4px;
  color: white;
  padding: 5px 30px;
}
.adminTable .accept {
  background: #f7aa26;
  border-radius: 4px;
  color: white;
  padding: 5px 30px;
}
.acceptButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}
.acceptButtons span {
  cursor: pointer;
}
.is_admin {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.is_admin input {
  transform: scale(1.5);
}
.is_admin p {
  margin-bottom: 0;
}
.adminTable .stopped {
  background: #ff0000;
  border-radius: 4px;
  color: white;
  padding: 5px 30px;
}
.adminButtonsCat button {
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
}

.malwareContentList {
  max-width: 20vw;
  max-height: 50px;
  text-align: left !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.maxItCenter {
  min-height: 80vh;
}
.maxItCenter textarea {
  min-height: 50vh !important;
}

.adminButtonsCat .usun {
  background: #ff0000;
  border: 1px solid #ff0000;
}

.adminButtonsCat .save {
  background: #1c9e1a;
  border: 1px solid #1c9e1a;
}
.multipleSelect#active {
  background: #ced4da;
}

.modalSendMessage textarea {
  padding: 20px;
  max-width: 70vw;
  border: 1px solid #ced4da;
  width: 500px;
}
.modalSendMessage textarea:focus {
  outline: none;
}
.baner_image {
  height: 40px;
  width: 80px;
  object-fit: cover;
}
.listPictureSmall {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.tableActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.secondFilters input {
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 30px;
  padding: 10px 20px;
}
.btnAddCategory {
  width: 100%;
  background: #fba81f;
  border-radius: 4px;
  color: white;
  padding: 5px 20px;
  border: 1px solid #fba81f;
  font-size: 19px;
}
.adminPagination .pageItem#active {
  background: #f7aa26;
  color: white;
  border: 1px solid #f7aa26;
}
.multipleButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.multipleButtons button {
  width: 40%;
}

.categoryForm {
  width: 100%;
  max-width: 500px;
  margin: auto;
  text-align: center;
}
.promoCode {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.promoCode .save {
  height: 50px;
  background: #1c9e1a;
  border: 1px solid #1c9e1a;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
}
.discountForm {
  width: 100%;
  max-width: 500px;
  text-align: left;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}
.categoryForm .fullWidth {
  width: 100%;
}
.category_label {
  display: flex;
  justify-content: space-around;
}

.category_label input {
  transform: scale(1.6);
}
.searchOffersPanel {
  font-size: 25px;
}
.categoryForm h3 {
  font-size: 22px;
  text-align: left;
}
.imageP {
  text-align: left;
}
.categoryForm #disabled .imageP {
  color: rgba(0, 0, 0, 0.25);
}
.categoryForm #disabled h3 {
  color: rgba(0, 0, 0, 0.25);
}
.imageSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer;
}
.categoriesButtonCatalog {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
  height: 50px;
  border: none;
  min-width: 200px;
}
#katalogImages .images img {
  height: 100%;
  width: 100%;
  display: flex;
  object-fit: contain;
}
.ideaPapier img {
  max-width: 400px;
}
#katalogImages .removeGalleryItem {
  color: red;
  font-size: 40px;
  position: absolute;
  right: 0px;
  top: -30px;
}
.red {
  color: red;
}
.green {
  color: green;
}
#katalogImages .imageInner {
  position: relative;
  height: 150px;
  object-fit: contain;
}
#katalogImages .image {
  cursor: pointer;
  text-align: center;
  height: 150px;
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.05);
}
.imageSection img {
  width: 50px;
  height: 50px;
}
.categoryForm {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forgetPassLink {
  font-size: 18px !important;
  color: #f7aa26;
}

.chatTop {
  margin-bottom: 5px;
  color: #f7aa26;
}
.messBody .time {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.messBody .time span {
  font-size: 14px;
  color: #495057;
}
.notMyMessage {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
/* .messagesEND {
  margin-top: 140px;
} */
.mobileMenuSidebarToggle {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 30px;
}
.messBody {
  width: fit-content;
  padding: 10px 20px;
  background: rgba(251, 168, 31, 0.1);
  border-radius: 8px 8px 8px 0px;
  margin: 10px 0;
}
.addImage {
  font-size: 40px;
  cursor: pointer;
}
.chatMessage {
  position: relative;
}
.chatIMAGE {
  height: 200px;
  width: auto;
  object-fit: cover;
  cursor: pointer;
}

.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
  justify-content: center;
}

.show_image {
  cursor: pointer;
  width: 90vw;
  height: 90vh;
  object-fit: contain;
}

.defaultCSV {
  background: #e0e0e0;
  border: 1.5px solid #e0e0e0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  padding: 15px 24px;
  font-weight: 700;
}

.csvBefore h6 {
  font-size: 22px;
}

.csvBefore p {
  font-size: 18px;
}

.csvBefore a {
  color: #fba81f;
  margin-left: 10px;
  font-weight: 500;
}
.socialLogin {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.LOGIN_REGISTER_MOBILE .socialLogin div {
  background: #f7aa26;
}
.LOGIN_REGISTER_MOBILE .socialLogin div svg {
  color: white;
  font-size: 16px;
}
.socialLogin div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.socialLogin svg {
  color: black;
  font-size: 16px;
}

.insertCSV {
  text-align: center;
}
.csvAfter .tableWrapper {
  overflow-x: scroll;
}
.csvInsert {
  background: #f7aa26;
  font-weight: 700;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: auto;
}
.topCSV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.massAction {
  background: #e0e0e0;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 700;
}
.massAdd {
  background: #f7aa26;
  border: 1px solid #f7aa26;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 700;
}

.categoryAdded {
  background: #f7aa26;
  border: 1px solid #f7aa26;
  border-radius: 6px;
  font-weight: 500;
  width: 200px;
  text-align: center;
}

.addCategory {
  background: #e0e0e0;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  font-weight: 500;
  width: 200px;
}

.btnAccpet {
  padding: 10px 20px;
  background: #f7aa26;
  border-radius: 6px;
  border: 1px solid #f7aa26;
  color: white;
}
.locationLines p {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addBaner {
  background: #f7aa26;
  padding: 10px 30px;
  border: 1px solid #f7aa26;
  font-weight: 600;
  border-radius: 6px;
  color: black;
}

.banerSuccess {
  font-size: 50px !important;
}

.circleGrey {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #d9d9d9;
  font-size: 40px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

#AddingBaner p {
  font-weight: 700;
  font-size: 20px;
}
.contactFormPage {
  width: 90vw;
  max-width: 500px;
  margin: auto;
}
.inputButtonCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: white;
  border: 4px solid black;
  cursor: pointer;
}

.inputButtonCircle#active {
  border-color: #f7aa26;
  background-color: #f7aa26;
}

.b1920,
.b800 {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 700;
  border: 6px solid #f7aa26;
}

.oneMonth {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.oneMonth .yellowCircle {
  width: 30px;
  height: 30px;
  background: #f7aa26;
  border-radius: 50%;
}

#AddingBaner .section {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
#AddingBaner .checkboxBaner {
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

#AddingBaner .checkboxBaner input {
  transform: scale(1.6);
}

.checkboxBaner label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#AddingBaner .checkboxBaner p {
  margin-bottom: 0;
  font-size: 18px;
  margin-left: 20px;
  font-weight: 400;
}

#AddingBaner strong {
  font-size: 30px;
  font-weight: 700;
  text-align: right;
}

.buttonsBottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 30px;
}

.buttonsBottom button {
  padding: 10px 20px;
  cursor: pointer;
  min-width: 300px;
  max-width: 100%;
  font-weight: 600;
}

.buttonsBottom .resign {
  background: #e0e0e0;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.buttonsBottom .accept {
  background: #f7aa26;
  border-radius: 6px;
  border: 1px solid #f7aa26;
}

.mobileMenuSidebar {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
  background: #2d2e33;
}

.mobileMenuSidebar .logoSidebar {
  display: flex;
  flex-direction: row;
}
.colorWhite {
  color: white !important;
}
.closePanelUser {
  font-size: 30px;
}

#thankyouRegister .row {
  height: 70vh;
}
.catalogLogo img {
  max-height: 500px;
}

@media all and (max-width: 1550px) {
  .registerImgWrapper .registerTitle {
    font-size: 70px;
  }
}

@media all and (max-width: 1500px) {
  .registerImgWrapper .registerTitle {
    font-size: 65px;
  }
}

@media all and (max-width: 1400px) {
  .registerImgWrapper .registerTitle {
    font-size: 50px;
  }
  .titleSearch .heroTitle {
    font-size: 30px;
  }
  .registerImgWrapper .yellow_circle {
    left: -8vh;
  }
  .sliderImage {
    height: 500px !important;
  }
  #banerCenter .row h2 {
    font-size: 60px;
  }
}

@media all and (max-width: 1300px) {
  .registerImgWrapper .yellow_circle {
    left: -10vh;
  }
  .thankYouOffer .buttons_row button {
    width: 300px;
  }
}

@media all and (max-width: 1200px) {
  .categoriesList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }
  .registerImgWrapper .yellow_circle {
    left: -14vh;
  }
  .sliderImage {
    height: 400px !important;
  }
  .registerImgWrapper .registerTitle {
    font-size: 50px;
  }
  .titleSearch .heroTitle {
    font-size: 26px;
  }
  #offerDetail .heroTitle {
    font-size: 50px;
    align-items: center;
  }
}

@media all and (max-width: 1100px) {
  .registerImgWrapper .yellow_circle {
    left: -16vh;
  }

  .registerImgWrapper .registerTitle {
    font-size: 50px;
  }
  .thankYouOffer .buttons_row button {
    width: fit-content;
  }
}

@media all and (max-width: 1100px) {
  .categoriesList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  #header .btn.add_offer,
  #header .btn.your_account,
  #header .btn.catalog {
    padding: 18px 10px;
    font-size: 14px;
  }
  .DeskopMenu .menu a {
    display: flex;
    flex-direction: row;
  }
}
.only_mobile {
  display: none !important;
}
.comebackGap {
  gap: 30px;
}
.myButtonsAdmin button {
  color: black;
}
.myButtonsAdminButton button.usun {
  color: white;
}
.catalogItem #desc {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.portfolioGallery {
  max-width: 800px;
  margin: auto;
  max-height: 500px;
}
.portfolioGallery .swiper-slide {
  max-height: 450px;
}
.portfolioGallery img {
  object-fit: cover;
}
.catalogDesc {
  word-break: break-all;
}
.Hero {
  z-index: 10;
}
.zMinus {
  z-index: 1;
}
.Hero img {
  max-width: 25vw;
}
.ReactModal__Overlay {
  z-index: 99999;
}
.userOfferTitle {
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.last_mess {
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
#mainPage {
  z-index: 1;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 0px) !important;
  right: auto;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 0px) !important;
  left: auto;
}
.CookieConsent {
  z-index: 999999 !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  color: black;
}
.onlyMobile {
  display: none !important;
}
.adresDateGrid {
  display: flex;
  gap: 10px;
}
@media all and (max-width: 767px) {
  .mobileDateAndAdresGrid {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mobileDateAndAdresGrid p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
  .gridProductWrapper .gridProductAddress {
    font-size: 16px;
    font-weight: 300;
  }
  .breadcamp a {
    white-space: nowrap;
    font-size: 13px;
  }
  .back_bread {
    gap: 10px;
  }
  .ideaPapier img {
    max-width: 90vw;
  }
  .messageContainer {
    height: 50vh;
  }
  .onlyMobile {
    display: flex !important;
  }
  #helpModal label input[type="checkbox"] {
    width: unset !important;
    height: unset !important;
    transform: unset !important;
  }
  .catalogContactData {
    width: 100%;
  }
  .catalogContactData .line {
    border-radius: 0;
  }
  .portfolioIMAGE img {
    object-fit: contain;
    height: auto;
    text-align: center;
    width: 100%;
  }
  .m-fullWidth .categories {
    width: 100%;
  }
  .users .user {
    width: 200px;
    min-width: 50vw;
    overflow: hidden;
  }
  .homepageCat p {
    font-size: 13px !important;
  }
  .detailOfferHero {
    font-size: 24px;
  }
  .CurstomRowProfile {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
  .CurstomRowProfile .change {
    margin: 10px auto;
  }
  .catalogDesc {
    padding: 10px;
  }
  .catalogcity {
    position: absolute;
    left: 10px;
    bottom: 5px;
  }
  .portfolioGallery {
    max-height: 400px;
  }
  .portfolioGallery .swiper-slide {
    max-height: 350px;
  }
  .catalogLogo img {
    max-height: 300px;
  }
  #catalogLink {
    padding: 0;
    text-align: center;
  }
  #catalogLink img {
    height: 150px;
    margin-bottom: 20px;
    width: 100%;
    object-fit: cover;
  }
  .sectionButtons a {
    width: 100%;
  }
  #offerDetail .wishlist svg {
    color: white;
  }
  #panel .accordion {
    padding-bottom: 10vh;
  }
  #helpModal input,
  #helpModal textarea {
    width: 90%;
  }
  .productData .tagInfo button {
    color: black;
  }
  #helpModal input[type="checkbox"] {
    width: 10px;
    height: 10px;
    margin: auto;
    margin-right: 10px;
    transform: scale(1.2);
  }
  #userDetail input {
    max-width: 90vw;
  }
  .myButtonsAdmin {
    flex-direction: column;
  }
  .adminButtonsCat {
    flex-direction: column;
  }
  .adminButtonsCat button {
    padding: 5px 10px;
    width: 100%;
  }
  #admin .sFilter {
    min-width: 300px;
  }
  #admin .sFilter input {
    width: 100%;
    max-width: 300px;
  }
  #admin .secondFilters {
    width: max-content !important;
  }

  .filterAdmin {
    width: max-content;
  }
  .threads .buttonsRow button {
    font-size: 16px;
  }
  .section {
    padding: 10px;
  }
  .section .imageWrapperForm {
    flex-direction: column !important;
    align-self: center;
    justify-content: center;
    text-align: center;
  }
  .messageInput form input {
    width: 85%;
  }
  .messageInput button {
    width: 15%;
  }
  .noMobile {
    display: none !important;
  }
  .images {
    justify-content: center;
    width: 100vw;
  }
  .images .image {
    padding: 10px;
  }
  .makeStyles-image-6 {
    height: 100px !important;
    object-fit: contain;
  }
  .ReactModal__Content {
    max-width: 90vw;
    overflow: hidden;
  }
  #helpModal input,
  #helpModal textarea,
  .categories-modal-container {
    max-width: 80vw;
  }
  #helpModal button {
    max-width: 80vw;
  }
  #helpModal .body {
    max-width: 80vw;
  }
  .image .imageInner img {
    height: 100px;
    object-fit: contain;
  }
  .only_mobile {
    display: block !important;
  }
  .threads .users {
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    height: fit-content;
    min-height: fit-content;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
  }
  #deleteRowId {
    justify-content: space-between;
  }
  #listOfferPanelBottom {
    justify-content: space-between;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 5px;
  }
  #listOfferPanelBottom button,
  #deleteRowId .delete {
    padding: 8px 15px !important;
  }
  .adminRowTriple {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .newKatalogs,
  .hello {
    height: fit-content;
  }
  #banerCenter .row h2 {
    font-size: 30px !important;
  }
  .shape1,
  .shape0 {
    display: none;
  }
  .loginWrapper h3 {
    color: black;
  }
  .gridProductWrapper img {
    height: 100px;
  }
  .gridProductWrapper {
    height: 250px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .swiper-wrapper img {
    object-fit: contain;
  }
  .underTextArea p {
    font-size: 16px !important;
  }
  #addOffer h1 {
    margin-top: 20px;
    font-size: 24px;
  }
  #addOffer h3 {
    margin-top: 20px;
    font-size: 18px;
  }
  .gridProductWrapper .description {
    text-align: left;
  }
  .wishlist {
    width: 10%;
  }

  .categoriesList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .promosection .image {
    position: absolute;
    width: 100px;
    top: 0;
  }
  .promosection h3,
  .promosection h4 {
    font-size: 28px;
  }
  .pricePromo {
    padding-right: 20px;
  }
  .promoTitle {
    width: 60%;
    margin-left: auto;
  }
  .promosection {
    position: relative;
    margin-top: 30px;
  }
  .mobileCenter {
    text-align: center;
  }
  .sectionButtons {
    flex-wrap: wrap;
    justify-content: center;
  }
  .sectionButtons button {
    width: 100%;
  }
  .analytics {
    text-align: center;
    font-size: 14px;
  }
  .mobileWishList {
    position: absolute;
    right: 10px;
    width: 45px;
    height: 45px;
    background: #f7aa26;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .back_bread .breadcamp {
    font-size: 14px;
  }
  #offerDetail .heroTitle {
    font-size: 22px !important;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0;
    gap: 10px;
    justify-content: center;
  }
  #offerDetail .heroTitle img {
    max-height: 40px;
  }
  .heroTitle img {
    max-height: 40px;
  }
  #offerDetail .heroTitle span {
    line-height: 30px;
    width: fit-content;
  }
  #offerDetail .yellow_circle {
    width: 50px;
    height: 50px;
  }
  .swichMobileColumns {
    display: flex;
    flex-direction: column-reverse;
  }
  .productData .tagInfo button {
    padding: 5px 20px;
  }
  .productData .tagInfo {
    justify-content: space-between;
  }
  .bottomLine h1 span {
    font-size: 30px;
    line-height: 30px !important;
  }
  .bottomLine .heroTitle {
    gap: 40px;
    justify-content: unset;
  }
  .bottomLine .heroTitle .yellow_circle {
    height: 50px;
    width: 50px;
  }
  #listProduct .productTitle {
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #listProduct .highlighted {
    font-size: 16px;
    height: 25px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #listProduct .image {
    height: 100%;
    display: flex;
    align-items: center;
  }
  #listProduct .description button {
    padding: 3px 20px;
  }
  #listProduct .date {
    font-size: 1rem;
  }
  .adresDateGrid {
    margin-bottom: 0;
    max-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mobilenopadding {
    padding: 0 !important;
  }
  .no_mobile {
    display: none !important;
  }
  .gridProductWrapper p.productTitle {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
  .gridProductWrapper span {
    font-size: 15px !important;
  }
  .gridProductWrapper .price_list .price {
    font-size: 18px;
  }
  .gridProductWrapper .price_list {
    justify-content: space-between;
  }
  #menu .DeskopMenu {
    display: none !important;
  }
  .mobileMenu {
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    padding-right: 20px;
  }
  .heroTitle {
    font-size: 50px;
    justify-content: space-between;
    align-items: center;
  }
  .heroTitle span {
    width: 50%;
    line-height: 50px;
  }
  #searchContainer {
    padding-bottom: 30px !important;
  }
  #mainPage {
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }
  #checkoutPromo .buttonsPrice button.priceButton {
    width: 50%;
    max-width: 50%;
  }
  .buttonsBottom {
    justify-content: center;
  }
  #checkoutPromo .buttonsPrice {
    width: 100%;
  }
  .b1920 {
    margin-bottom: 20px;
  }
  #mainPage .title {
    font-size: 30px;
  }
  #mainPage .title span {
    width: 50%;
    line-height: 35px;
  }
  .footerAdvertisement h3 {
    font-size: 20px;
  }
  .ideaPapier .ideaHeader {
    font-size: 22px;
  }
  .ideaPapier .description {
    font-size: 18px;
  }
  .copyright {
    margin-bottom: 0rem;
    font-size: 14px;
  }
  .textCenterMobile {
    text-align: center;
  }
  .social {
    justify-content: center !important;
  }
  .logoFooterText {
    margin: auto;
  }
  .menuHide {
    display: none !important;
  }
  .titleSearch {
    height: 100%;
    align-items: center;
    display: flex;
    align-items: center;
  }
  .titleSearch .heroTitle {
    justify-content: flex-start;
    gap: 10px;
  }
  .titleSearch .heroTitle span {
    width: fit-content;
    line-height: normal;
    font-size: 16px;
  }
  .titleSearch .yellow_circle {
    width: 20px;
    height: 20px;
  }
  .singleProductImg .sliderImage {
    height: 60vw !important;
  }
  .mobileCenter {
    text-align: center !important;
    justify-content: center !important;
  }
  html #header .heromobiletitle {
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }
  #searchWrapper .searchButton {
    padding: 8px 10px;
  }
}

@media all and (min-width: 767px) {
  .filtersMobile {
    display: none;
  }
  .mobileMenu {
    display: none;
  }
  .menuHide {
    display: none !important;
  }
  .only_mobile {
    display: none;
  }
}

@media only screen and (min-width: 991px) {
  .mobileStickyBottom {
    display: none !important;
  }
  .desktop-fixed {
    position: fixed;
  }
  .fromTablet {
    display: none;
  }
  .registerCloseWrap.fromTablet {
    display: none;
  }
}

@supports (padding-top: env(safe-area-inset-bottom)) {
  .mobileStickyBottom {
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-bottom);
  }
}
.catalogContactData p {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.gridProductWrapper .gridProductAddress {
  font-size: 16px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) {
  .CompanyInfoWrapper .inner {
    padding: 10px 20px;
  }
  .homepageCat p {
    font-size: 14px;
  }
  #banerCenter #thankYou {
    display: none;
  }
  #banerCenter .row h2 {
    font-size: 40px;
  }
  #banerCenter {
    padding: 20px 0;
    align-items: start;
    height: fit-content;
    min-height: fit-content;
  }
  .mobileStickyBottom {
    background-color: #fff;
    border-top: 1px solid #eee;
    bottom: 0;
    display: flex !important;
    flex-direction: row;
    height: 60px;
    position: fixed;
    transition: all 1s ease-in-out;
    width: 100vw;
    left: 0;
    z-index: 9999;
  }
  .mobileStickyBottom .bottomMenu {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-around;
    width: 100%;
    z-index: 999999;
  }
  .mobileStickyBottom .bottomMenuItem a {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    text-decoration: none;
    gap: 2px;
  }
  .mobileStickyBottom .bottomMenuItem a svg {
    font-size: 20px;
  }
  .mobileStickyBottom .bottomMenuItem {
    width: 20vw;
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    justify-content: center;
    padding: 0 10px;
  }
  .mobileStickyBottom .bottomMenuItem#active {
    background: #ec930f;
    color: #fff;
  }
  .swiper-slide {
    height: 300px !important;
  }
  #centerWrapper .thankYouOffer h2 {
    font-size: 32px;
  }
  .thankYouOffer .buttons_row {
    flex-wrap: wrap;
    gap: 0px;
  }
  .thankYouOffer .buttons_row a {
    width: 100%;
  }
  .thankYouOffer .buttons_row button {
    width: 100%;
    margin-bottom: 30px !important;
  }

  #centerWrapper.mobile {
    height: fit-content;
  }
  #thankyouRegister {
    background: #2d2e33;
  }
  #offerDetail .heroTitle {
    font-size: 40px;
    align-items: center;
  }
  .sliderImage {
    height: 300px !important;
  }
  .highlighted {
    bottom: -0.5rem;
    padding: 0 20px;
    font-size: 15px;
  }
  .cornerEclipse {
    width: 100px;
    height: 100px;
  }
  .titleSearch .heroTitle {
    font-size: 22px;
  }
  #thankyouRegister .imgWrapper {
    padding-top: 30px;
  }
  .largerCircle {
    position: absolute;
    top: 2.5vh;
    width: 95vh;
    height: 95vh;
    background-color: #ecc80f;
    z-index: 1;
    right: -20vh;
    overflow: hidden;
    border-radius: 50%;
  }
  .largeCircle {
    position: absolute;
    top: 7.5vh;
    width: 85vh;
    height: 85vh;
    background-color: #000000;
    z-index: 1;
    border-radius: 50%;
  }
  #centerWrapper .row {
    flex-direction: column-reverse;
    height: fit-content;
  }
  #centerWrapper h2 {
    font-size: 32px;
    padding: 30px;
    color: black;
    font-weight: 700;
    padding-top: 5vh;
  }

  .myWrapper p {
    max-width: 300px;
    margin-bottom: 5vh !important;
  }
  .myWrapper button {
    margin-bottom: 5vh !important;
    padding: 15px 20px;
  }
  .ThankYouWrapper {
    justify-content: flex-start;
  }
  #thankYou p {
    transform: none;
    font-size: 32px;
    z-index: 999;
  }
  #thankYou {
    position: relative;
  }
  #thankYou .yellow_circle {
    position: absolute;
    right: 0px;
    bottom: -50px;
    z-index: -1;
  }
  .register.left-side {
    background: url("assets/registerMoblineBottom.png") no-repeat bottom #2d2e33;
    background-size: contain;
  }
  .registerImgWrapper {
    padding: 160px 0;
  }
  .registerImgWrapper .yellow_circle {
    width: 40px;
    height: 40px;
    top: -20px;
    left: 95%;
    z-index: 0;
  }
  .figuresBottom {
    position: absolute;
    max-height: 150px;
    bottom: -200px;
  }
  .registerImgWrapper {
    max-width: 95%;
    margin-top: unset;
  }
  .noTablet {
    display: none !important;
  }
  .onCenter.fromTablet {
    height: fit-content;
  }
  .register.secondPage {
    display: none;
  }
  #menu .DeskopMenu {
    justify-content: space-around !important;
  }
  #header #menu .btn.your_account,
  #header #menu .btn.add_offer,
  #header .btn.catalog {
    padding: 10px;
    font-size: 12px;
    height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #header .buttons {
    gap: 10px;
  }
  .registerCloseWrap svg {
    font-size: 30px;
    color: white;
  }
}

@media only screen and (min-width: 991px) {
  .desktop-fixed {
    position: fixed;
  }
  .onCenter.fromTablet,
  .registerCloseWrap.fromTablet {
    display: none;
  }
}
.mobileMenuUser {
  display: none;
}
.profileInfoRow {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1199px) {
  .mobileMenuUser .sidebarItem {
    background: #ffffff;
    border-radius: 6px;
    padding: 10px 20px;
    color: black;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .mobileMenuUser {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background: #2d2e33;
    z-index: 99999999;
    padding: 20px;
  }
  #sidebar {
    padding: 0 !important;
  }
  .mobileSidebar {
    display: flex;
  }
  .sidebar {
    display: none !important;
  }
  #sidebar {
    min-height: unset;
    max-height: unset;
  }
  .sidebar_toggle {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 30px;
    color: black;
    cursor: pointer;
  }
  .logoSidebar {
    margin-top: 20px;
    max-width: 400px;
    width: 70%;
  }
  #panelContent {
    padding-left: 0;
  }
  .sidebarNo {
    display: block !important;
  }
  .sidebarYes {
    display: none !important;
  }
  .deskopFilters {
    display: none;
  }
}

@media only screen and (min-width: 1199px) {
  .mobFilters {
    display: none;
  }
  .mobileSidebar {
    display: none;
  }
  .sidebarYes {
    display: block;
  }
  .sidebarNo {
    display: none !important;
  }
}
